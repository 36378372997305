define("cc-frontend/lib/actions/app-state/KB_HAPPY_FACE_CLICKED", ["exports", "cc-frontend/lib/actions/create-narrative", "lodash-es"], function (_exports, _createNarrative, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareKbHappyFaceClicked = prepareKbHappyFaceClicked;

  function prepareKbHappyFaceClicked(payload) {
    return {
      name: "KB_HAPPY_FACE_CLICKED",
      payload: payload,
      patches: null,
      undoPatches: null,
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function narrative(payload, _findFn, _userId) {
    return (0, _createNarrative.default)({
      titleForAnalytics: "KB Article - Happy Face Clicked",
      context: {
        articleId: payload.article.id,
        articleTitle: payload.article.attributes.title
      }
    });
  }
});