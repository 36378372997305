define("cc-frontend/router", ["exports", "cc-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route("suggestions", {
      path: "/ideas"
    });
    this.route("help", {
      path: "/help"
    }, function () {
      this.route("contact", {
        path: "/contact-us"
      });
      this.route("all", {
        path: "/all"
      });
      this.route("article", {
        path: "/:article_id"
      });
      this.route("index", {
        path: "/"
      });
    });
    this.route("user", {
      path: "/users"
    }, function () {
      this.route("sign", {
        path: "/sign"
      });
      this.route("sign-in", {
        path: "/sign_in"
      });
      this.route("sign-up", {
        path: "/sign_up"
      });
      this.route("sign-out", {
        path: "/sign_out"
      });
      this.route("onboarding", {
        path: "/onboarding"
      });
      this.route("reset-password", {
        path: "/reset-password"
      });
      this.route("reset-password-confirmation", {
        path: "/reset-password-confirmation"
      });
      this.route("edit-password", {
        path: "/password/edit"
      });
      this.route("show", {
        path: "/:user_id"
      }, function () {
        this.route("account", {
          path: "/account"
        });
      });
    });
    this.route("session", {
      path: "/sessions/:id"
    });
    this.route("new-group", {
      path: "/groups"
    }, function () {
      this.route("show", {
        path: "/:id"
      }, function () {
        this.route("reviews", {
          path: "/reviews"
        });
        this.route("edit", {
          path: "/setup"
        }, function () {
          this.route("admins", {
            path: "/admins"
          });
          this.route("members", {
            path: "/members"
          }, function () {
            this.route("new", {
              path: "/new"
            });
          });
          this.route("teams", {
            path: "/teams"
          }, function () {
            this.route("show", {
              path: "/:team_id"
            });
          });
          this.route("card-stack-templates", {
            path: "/templates"
          });
          this.route("calendar", {
            path: "/calendar"
          });
          this.route("payment", {
            path: "/payment"
          });
        });
      });
    });
    this.route("admin", function () {
      this.route("user", {
        path: "users/:id"
      });
      this.route("user-actions", {
        path: "user-actions/:id"
      });
      this.route("user-sessions", {
        path: "user-session/:id"
      });
      this.route("planbook", {
        path: "planbooks/:id"
      });
      this.route("course", {
        path: "courses/:id"
      });
      this.route("group", {
        path: "groups/:id"
      });
      this.route("rotation-calendar", {
        path: "rotation-calendar/:id"
      });
      this.route("card-stack", {
        path: "card-stacks/:id"
      });
      this.route("action", {
        path: "actions/:id"
      });
      this.route("session", {
        path: "sessions/:id"
      });
      this.route("fiscal-group", {
        path: "fiscal-groups/:id"
      });
      this.route("class-website", {
        path: "class-websites/:id"
      });
      this.route("send-in-app-message", {
        path: "in-app-message/send"
      });
      this.route("impersonate");
      this.route("groups-old-admin", {
        path: "groups-old-admin"
      }, function () {
        this.route("new", {
          path: "/new"
        });
        this.route("show", {
          path: "/:id"
        });
      });
      this.route("school-years", {
        path: "school_years"
      }, function () {
        this.route("new", {
          path: "/new"
        });
        this.route("show", {
          path: "/:id"
        });
      });
      this.route("holidays", {
        path: "holidays"
      }, function () {
        this.route("new", {
          path: "/new"
        });
        this.route("show", {
          path: "/:id"
        });
      });
      this.route("fiscal-groups", {
        path: "fiscal_groups"
      }, function () {
        this.route("edit", {
          path: "/:id"
        });
      });
      this.route("docs", {
        path: "/docs/:id"
      }, function () {
        // eslint-disable-next-line ember/routes-segments-snake-case
        this.route("version", {
          path: "/version/:versionNumber"
        });
        this.route("action", {
          path: "/action/:action_id"
        });
      }); // CSP admin

      this.route("csp", {
        path: "csp"
      }, function () {
        this.route("show", {
          path: "/:id"
        }, function () {
          this.route("standard-set", {
            path: "/standard-set/:standard_set_id"
          });
        });
      });
    });
    this.route("quote", {
      path: "/quotes/:deal_id"
    });
    this.route("lesson", {
      path: "/lessons/:lesson_id"
    }); // this.route("courseLesson", { path: "/lessons/:planbook_id/:course_id/:date_string" })

    this.route("student-course-date", {
      path: "/student-lesson/:course_id/:date_string"
    });
    this.route("invites", {
      path: "/invites"
    }, function () {
      this.route("accept", {
        path: "/accept/:invite_id"
      });
    });
    this.route("planbookv5", {
      path: "/planbooks/:planbook_id"
    }, function () {
      this.route("planner", {
        path: "/planner"
      }, function () {
        this.route("day", {
          path: "/day"
        });
        this.route("week", {
          path: "/week"
        });
        this.route("month", {
          path: "/month"
        });
        this.route("timeline", {
          path: "/timeline"
        });
        this.route("outline", {
          path: "/outline"
        });
        this.route("year", {
          path: "/year"
        });
        this.route("import-lessons", {
          path: "/import-lessons"
        });
        this.route("not-found", {
          path: "/*path"
        });
      });
      this.route("standards-tracker", {
        path: "/standards-tracker"
      });
      this.route("sharing", {
        path: "/sharing"
      });
      this.route("class-websites", {
        path: "/class-websites"
      });
      this.route("search", {
        path: "/search"
      });
      this.route("not-found", {
        path: "/*path"
      });
    });
    this.route("print", {
      path: "/print"
    }, function () {
      this.route("course-date", {
        path: "/course-date/:course_id"
      });
      this.route("planbook-date", {
        path: "/planbook-date/:planbook_id"
      });
      this.route("planbook-month", {
        path: "/planbook-month/:planbook_id"
      });
      this.route("planbook-standards", {
        path: "/planbook-standards/:planbook_id"
      });
      this.route("course-outline", {
        path: "/course-outline/:course_id"
      });
      this.route("course-unit", {
        path: "/course-unit/:course_id"
      });
      this.route("course-units", {
        path: "/course-units/:course_id"
      });
      this.route("stripe-invoice", {
        path: "/stripe-invoice/:id"
      });
      this.route("quote", {
        path: "/quotes/:deal_id"
      }); // this.route('outline', {path: '/outline'})
    });
    this.route("freestyle");
    this.route("class-website", {
      path: "/sites/:slug"
    });
    this.route("features", {
      path: "/pages/features"
    });
    this.route("schools", {
      path: "/pages/schools"
    });
    this.route("why-cc", {
      path: "/pages/why-cc"
    });
    this.route("pricing", {
      path: "/pages/pricing"
    });
    this.route("health", {
      path: "/pages/health"
    });
    this.route("not-found", {
      path: "/*path"
    });
  });
});