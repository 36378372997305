define("cc-frontend/models/course-calendar-date-custom", ["exports", "lodash-es", "cc-frontend/lib/structor_2"], function (_exports, _lodashEs, _structor_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createCourseCalendarDateCustomAttributes = createCourseCalendarDateCustomAttributes;
  _exports.default = void 0;

  class CourseCalendarDateCustom extends _structor_.default {
    defineStruct() {
      return {
        dateString: null,
        cardStackId: null,
        time: null,
        isForcedOn: null,
        unitStart: [],
        unitEnd: [],
        unitIds: []
      };
    }

  }

  _exports.default = CourseCalendarDateCustom;

  function createCourseCalendarDateCustomAttributes(options) {
    let defaults = {
      cardStackId: null,
      time: null,
      isForcedOn: false,
      unitStart: [],
      unitEnd: [],
      unitIds: [],
      dateString: "" // This is just to make TS happy

    };
    (0, _lodashEs.forEach)(options, (v, k) => {
      defaults[k] = v;
    });
    return defaults;
  }
});