define("cc-frontend/models/course-calendar-date-default", ["exports", "cc-frontend/lib/structor_2"], function (_exports, _structor_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CourseCalendarDateDefault extends _structor_.default {
    defineStruct() {
      return {
        rotationId: null,
        semesterId: null,
        cardStackId: null,
        time: null
      };
    }

  }

  _exports.default = CourseCalendarDateDefault;
});