define("cc-frontend/lib/unit-utils", ["exports", "@mobily/ts-belt", "@sentry/browser", "cc-frontend/models/course", "cc-frontend/models/course-calendar-date-custom", "cc-frontend/utils/filter-dates/course", "lodash-es", "cc-frontend/models/transferrable-date"], function (_exports, _tsBelt, Sentry, _course, _courseCalendarDateCustom, _course2, _lodashEs, _transferrableDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.consolidateCourseDatesOff = consolidateCourseDatesOff;
  _exports.extractRange = extractRange;
  _exports.extractLesson = extractLesson;
  _exports.extractUnit = extractUnit;
  _exports.findNewDate = findNewDate;
  _exports.insertRange = insertRange;
  _exports.simpleInsertRange = simpleInsertRange;
  _exports.moveEndOfUnit = moveEndOfUnit;
  _exports.moveStartOfUnit = moveStartOfUnit;
  _exports.constructUnitsAndDates = constructUnitsAndDates;
  _exports.getCardStackIdForDate = getCardStackIdForDate;
  _exports.getCourseDateForCardStackId = getCourseDateForCardStackId;

  /**
   *
   * @param {Date} date
   */
  function formatDateAsISO(date) {
    return dateFns.format(date, "YYYY-MM-DD");
  }

  function without(arr, ...values) {
    return arr.reject(value => values.includes(value));
  }

  function cloneDeep(value) {
    return JSON.parse(JSON.stringify(value));
  }

  function intersection(arr1, arr2) {
    return (arr1 || []).filter(value => (arr2 || []).includes(value));
  }
  /**
   *
   * @param {Number} amount
   * @param {Number} unitLength
   * @param {String[]} unitAncestorIds
   * @param {String[]} dateUnitIds
   */


  function isMovingParentUnit(amount, unitLength, unitAncestorIds, dateUnitIds) {
    return amount > 0 && // if we're draggin forward
    amount < unitLength && // if we haven't dragged it past it's end
    intersection(dateUnitIds, unitAncestorIds).length > 0 // the date in question contains the ancestor
    ;
  }
  /**
   * Loops through all the rotation dates and toggles off any custom or default dates.
   * Then, we take out any dates being forced on.
   *
   * @param {Array<string>} planbookDatesOff
   * @param {Array<String>} planbookDatesForcedOn
   * @param {Array<String>} courseDatesOff
   * @param {Array<String>} courseDatesForcedOn
   * @param {Array<Object>} rotationDates
   */


  function consolidateCourseDatesOff(course, planbook, rotationCalendar) {
    let courseDatesOff = new Set(course.attributes.calendar.datesOff);
    let courseDatesForcedOn = new Set(course.attributes.calendar.datesForcedOn);
    let planbookDatesOff = new Set(planbook.attributes.calendar.datesOff);
    let rotationDates = rotationCalendar.attributes.dates;
    let unitStartDates = new Set(_tsBelt.A.map(course.attributes.calendar.units, unit => unit.startDate));
    let unitEndDates = new Set(_tsBelt.A.map(course.attributes.calendar.units, unit => unit.endDate)); // loop through rotationDates
    // - convert all the semester/rotationIds to find out if it's is on.
    // then, we only have to look to see if it's the dateString is off.

    return (0, _tsBelt.pipe)(rotationDates, _tsBelt.A.filter(date => {
      let semesterRotationString = date.attributes.semesterId + ":" + date.attributes.rotationId;
      let planbookDefaultIsOff = planbookDatesOff.has(semesterRotationString);
      let courseDefaultIsOff = courseDatesOff.has(semesterRotationString);
      let planbookCustomIsOff = planbookDatesOff.has(date.attributes.dateString);
      let courseCustomIsOff = courseDatesOff.has(date.attributes.dateString);
      let dateHasStartOfUnit = unitStartDates.has(date.attributes.dateString);
      let dateHasEndOfUnit = unitEndDates.has(date.attributes.dateString);
      let planbookIsOff = planbookCustomIsOff ? planbookCustomIsOff : planbookDefaultIsOff;
      let courseIsOff = courseCustomIsOff ? courseCustomIsOff : courseDefaultIsOff;
      let isOff = planbookIsOff ? planbookIsOff : courseIsOff;
      let courseDateForcedOn = courseDatesForcedOn.has(date.attributes.dateString);
      return isOff && !courseDateForcedOn && !dateHasStartOfUnit && !dateHasEndOfUnit;
    }), _tsBelt.A.map(rotationDate => rotationDate.attributes.dateString));
  }
  /**
   * This is used when pulling a range of lessons and moving them. For instance,
   * let's say you wnat to add a week for Spring Break and you want to mvoe all the lessons
   * that are there. This will extract those lessons and units
   */


  function extractRange(startDate, endDate, courseDates, datesOff, schoolDays) {
    let date = dateFns.parse(startDate);
    let courseDateMap = new Map(Object.entries((0, _lodashEs.keyBy)((0, _lodashEs.filter)(courseDates, _course2.isCourseDateCustom), "attributes.dateString")));
    let iterations = 0;
    let isIterating = true;
    let transferrableArray = [];
    let modifiedDates = new Map();
    let schoolDaysSet = new Set(schoolDays);
    let datesOffSet = new Set(datesOff);

    while (isIterating && iterations < 500) {
      if (date > endDate) {
        isIterating = false;
        continue;
      }

      iterations++;
      let dateString = formatDateAsISO(date);

      if (!schoolDaysSet.has(dateFns.getDay(date))) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      if (datesOffSet.has(dateString)) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      let courseDate = courseDateMap.get(dateString) ? courseDateMap.get(dateString) : undefined;

      if (courseDate) {
        transferrableArray.push((0, _transferrableDate.createTransferrableDate)({
          cardStackId: courseDate.attributes.cardStackId,
          unitStart: courseDate.attributes.unitStart,
          unitEnd: courseDate.attributes.unitEnd
        }));
      }

      if (courseDate !== undefined) {
        let cloned = cloneDeep(courseDate);
        cloned.attributes.cardStackId = null;
        cloned.attributes.unitStart = [];
        cloned.attributes.unitEnd = [];
        cloned.attributes.unitIds = [];
        modifiedDates.set(dateString, cloned);
      }

      date = dateFns.addDays(date, 1);
    }

    let courseDatesWithoutRange = _tsBelt.A.map(courseDates, courseDate => {
      return (0, _course2.isCourseDateCustom)(courseDate) ? modifiedDates.get(courseDate.attributes.dateString) || courseDate : courseDate;
    });

    return {
      transferrableArray,
      courseDatesWithoutRange
    };
  }

  function extractLesson(cardStackId, courseDates) {
    if (cardStackId === null) return {
      transferrableArray: [],
      courseDatesWithoutLesson: courseDates
    };
    let transferrableArray = [(0, _transferrableDate.createTransferrableDate)({
      cardStackId: cardStackId,
      unitStart: [],
      unitEnd: []
    })];

    let courseDatesWithoutLesson = _tsBelt.A.map(courseDates, courseDate => {
      if ((0, _course2.isCourseDateCustom)(courseDate) && courseDate.attributes.cardStackId === cardStackId) {
        let cd = cloneDeep(courseDate);
        cd.attributes.cardStackId = null;
        return cd;
      } else {
        return courseDate;
      }
    });

    return {
      transferrableArray,
      courseDatesWithoutLesson
    };
  }
  /**
   * Pull out the unit and return a transferrable array and the course dates sans that array
   *
   * @param {Number} amount
   * @param {Object} unitHash
   * @param {Array<Object>} courseDates
   * @param {Array<String>} datesOff
   * @param {Array<number>} schoolDays
   */


  function extractUnit(amount, unitHash, courseDates, datesOff, schoolDays) {
    let unitStartDate = unitHash.startDate;
    let unitEndDate = unitHash.endDate;
    let unitIdsOnTheMove = (0, _lodashEs.flatten)([unitHash.descendantIds, unitHash.id]);
    let range = createRange(unitStartDate, unitEndDate, courseDates, datesOff, schoolDays);
    let initialAccumulator = {
      transferrableArray: new Array(),
      modifiedDates: new Map()
    };

    let {
      transferrableArray,
      modifiedDates
    } = _tsBelt.A.reduce(range, initialAccumulator, (acc, courseDate) => {
      // Phase 1: Unit End
      // -----------------
      let unitStart = intersection(courseDate.attributes.unitStart, unitIdsOnTheMove); // Phase 2: Unit End
      // -----------------
      // Tricky test -- if we're dragging a unit forward that's at the end of the unit, we want to also move the parent unit

      let moveParentUnits = isMovingParentUnit(amount, unitHash.unitLength, unitHash.ancestorIds, courseDate.attributes.unitIds);
      let unitEnd = moveParentUnits ? courseDate.attributes.unitEnd.slice(0) // take entire array including parents
      : intersection(courseDate.attributes.unitEnd, unitIdsOnTheMove); // take normal array of units on the move

      acc.transferrableArray.push((0, _transferrableDate.createTransferrableDate)({
        cardStackId: courseDate.attributes.cardStackId,
        unitStart: unitStart,
        unitEnd: unitEnd
      }));
      acc.modifiedDates.set(courseDate.attributes.dateString, {
        id: courseDate.id,
        type: courseDate.type,
        attributes: (0, _courseCalendarDateCustom.createCourseCalendarDateCustomAttributes)({
          isForcedOn: false,
          unitIds: [],
          cardStackId: null,
          time: null,
          dateString: courseDate.attributes.dateString,
          unitStart: without(courseDate.attributes.unitStart, ...unitIdsOnTheMove),
          unitEnd: moveParentUnits ? [] : without(courseDate.attributes.unitEnd, ...unitIdsOnTheMove)
        })
      });
      return acc;
    });

    let courseDatesWithoutUnit = _tsBelt.A.map(courseDates, courseDate => (0, _course2.isCourseDateCustom)(courseDate) ? modifiedDates.get(courseDate.attributes.dateString) || courseDate : courseDate);

    return {
      transferrableArray,
      courseDatesWithoutUnit
    };
  }

  function findNewDate(amount, oldStartDate, datesOff, schoolDays) {
    if (amount === 0) return oldStartDate;
    let dateOperation = amount > 0 ? dateFns.addDays : dateFns.subDays;
    let date = dateFns.parse(oldStartDate);
    let dateRange = [];
    let iterations = 0;
    let schoolDaySet = new Set(schoolDays);
    let datesOffSet = new Set(datesOff);

    while (dateRange.length < Math.abs(amount) && iterations < 500) {
      iterations++;
      date = dateOperation(date, 1);
      if (!schoolDaySet.has(dateFns.getDay(date))) continue;
      let dateString = formatDateAsISO(date);
      if (datesOffSet.has(dateString)) continue;
      dateRange.push(dateString);
    }

    return (0, _lodashEs.last)(dateRange);
  } // TODO If it's a lesson, we want to merge it if there's a unitStart -- we should pass that in.


  function insertRange(transferrableArray, newStartDate, amount, unitHash, courseDates, datesOff, schoolDays, isLesson) {
    let courseDateMap = new Map(Object.entries((0, _lodashEs.keyBy)((0, _lodashEs.filter)(courseDates, _course2.isCourseDateCustom), "attributes.dateString")));
    let newCourseDates = [];
    let modifiedDates = new Map();
    let date = dateFns.parse(newStartDate);
    let datesLaidDown = 0;
    let iterations = 0;
    let schoolDaysSet = new Set(schoolDays);
    let datesOffSet = new Set(datesOff);

    while (transferrableArray.length > 0 && iterations < 500) {
      iterations++;
      let dateString = formatDateAsISO(date);

      if (!schoolDaysSet.has(dateFns.getDay(date))) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      if (datesOffSet.has(dateString)) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      let courseDate = courseDateMap.get(dateString) ? cloneDeep(courseDateMap.get(dateString)) : undefined; //==================================================
      // Apply a date if a date doesn't exist
      //==================================================

      if (courseDate === undefined) {
        let dateToTransfer = transferrableArray.shift();

        if (dateToTransfer !== undefined) {
          newCourseDates.push({
            id: dateString,
            type: "course-date-custom",
            attributes: {
              dateString: dateString,
              cardStackId: dateToTransfer.cardStackId,
              unitStart: dateToTransfer.unitStart.slice(0),
              unitEnd: dateToTransfer.unitEnd.slice(0),
              unitIds: [],
              isForcedOn: false,
              time: null
            }
          });
          date = dateFns.addDays(date, 1);
          datesLaidDown++;
          continue;
        }
      } else {
        let isDraggingOntoStartOfParentUnit = unitHash && intersection(courseDate.attributes.unitStart, unitHash.ancestorIds).length > 0; //==================================================
        // Pluck and Reset Date (if needed)
        //==================================================

        let isApplyingFirstLesson = isLesson === true && datesLaidDown === 0;
        let nextTransferrableDate = (0, _lodashEs.first)(transferrableArray);

        if (courseDate !== undefined && nextTransferrableDate !== undefined && shouldPluckCourseDate(courseDate, amount, transferrableArray.length, isApplyingFirstLesson, nextTransferrableDate)) {
          // ------------------------
          // Step 1: Set unitStarts
          // ------------------------
          let dateToTransferUnitStart = [];
          let courseDateToModifyUnitStart = []; // Note: The first condition is to see if we're at the beginning and we're at the beginning of one the
          // unit's parent units. In that case, we don't want to push back the parent unit

          if (datesLaidDown === 0 && isDraggingOntoStartOfParentUnit) {
            let childUnitIdsToBump = unitHash ? without(courseDate.attributes.unitStart, ...unitHash.ancestorIds) : [];
            dateToTransferUnitStart = childUnitIdsToBump;
            courseDateToModifyUnitStart = without(courseDate.attributes.unitStart, ...childUnitIdsToBump);
          } else if (isApplyingFirstLesson) {
            dateToTransferUnitStart = [];
            courseDateToModifyUnitStart = courseDate.attributes.unitStart.slice(0);
          } else {
            dateToTransferUnitStart = courseDate.attributes.unitStart.slice(0);
            courseDateToModifyUnitStart = [];
          } // ----------------------------------
          // Step 2: Push date into transferrable array
          // ----------------------------------


          transferrableArray.push({
            cardStackId: courseDate.attributes.cardStackId,
            unitStart: dateToTransferUnitStart,
            unitEnd: courseDate.attributes.unitEnd.slice(0)
          }); // ----------------------------------
          // Step 3: Reset date
          // ----------------------------------

          courseDate.attributes.cardStackId = null;
          courseDate.attributes.unitStart = courseDateToModifyUnitStart;
          courseDate.attributes.unitEnd = [];
        } //==================================================
        // Apply a date
        //==================================================
        //------------------------
        // Figure out new unit end
        //------------------------


        let dateToTransfer = transferrableArray.shift();
        let newUnitEnd = [];

        if (dateToTransfer !== undefined) {
          if (courseDate && dateToTransfer && datesLaidDown === 0 && isDraggingOntoStartOfParentUnit) {
            newUnitEnd = intersection(courseDate.attributes.unitEnd, unitHash ? unitHash.ancestorIds : []).concat(dateToTransfer.unitEnd);
          } else {
            newUnitEnd = courseDate.attributes.unitEnd.concat(dateToTransfer.unitEnd);
          } //------------------------
          // Set properties on course date
          //------------------------


          courseDate.attributes.cardStackId = dateToTransfer.cardStackId;
          courseDate.attributes.unitStart = courseDate.attributes.unitStart.concat(dateToTransfer.unitStart);
        } // courseDate.attributes.unitEnd = courseDate.attributes.unitEnd.concat(dateToTransfer.unitEnd)


        courseDate.attributes.unitEnd = newUnitEnd;
        courseDate.attributes.unitIds = []; // reset it
        //----------------------
        // Overwrite old courseDate
        //----------------------

        modifiedDates.set(dateString, courseDate); //-------------------
        // Increment counters
        //-------------------

        datesLaidDown++;
        date = dateFns.addDays(date, 1);
      }
    }

    return _tsBelt.A.map(courseDates, cd => (0, _course2.isCourseDateCustom)(cd) ? modifiedDates.get(cd.attributes.dateString) || cd : cd).concat(newCourseDates);
  } //  We use this when we just want to throw a range in and we're not really merging. For instance,
  //  PULL_LESSON_BACKWARDS


  function simpleInsertRange(transferrableArray, newStartDate, courseDates, datesOff, schoolDays) {
    let courseDateMap = new Map(Object.entries((0, _lodashEs.keyBy)((0, _lodashEs.filter)(courseDates, _course2.isCourseDateCustom), "attributes.dateString")));
    let newCourseDates = [];
    let modifiedDates = new Map();
    let date = dateFns.parse(newStartDate);
    let datesLaidDown = 0;
    let iterations = 0;

    while (transferrableArray.length > 0 && iterations < 500) {
      iterations++;
      let dateString = formatDateAsISO(date);

      if (!_tsBelt.A.includes(schoolDays, dateFns.getDay(date))) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      if (_tsBelt.A.includes(datesOff, dateString)) {
        date = dateFns.addDays(date, 1);
        continue;
      }

      let courseDate = courseDateMap.get(dateString) ? cloneDeep(courseDateMap.get(dateString)) : undefined; //==================================================
      // Apply a date if a date doesn't exist
      //==================================================

      if (courseDate === undefined) {
        let dateToTransfer = transferrableArray.shift();

        if (dateToTransfer !== undefined) {
          newCourseDates.push({
            id: dateString,
            type: "course-date-custom",
            attributes: (0, _courseCalendarDateCustom.createCourseCalendarDateCustomAttributes)({
              dateString: dateString,
              cardStackId: dateToTransfer.cardStackId,
              unitStart: dateToTransfer.unitStart.slice(0),
              unitEnd: dateToTransfer.unitEnd.slice(0),
              unitIds: [],
              isForcedOn: false,
              time: null
            })
          });
        }

        date = dateFns.addDays(date, 1);
        datesLaidDown++;
        continue;
      } //------------------------
      // Set properties on course date
      //------------------------


      let dateToTransfer = transferrableArray.shift();

      if (dateToTransfer !== undefined) {
        courseDate.attributes.cardStackId = dateToTransfer.cardStackId;
        courseDate.attributes.unitStart = courseDate.attributes.unitStart.concat(dateToTransfer.unitStart); // courseDate.attributes.unitEnd = courseDate.attributes.unitEnd.concat(dateToTransfer.unitEnd)

        courseDate.attributes.unitEnd = dateToTransfer.unitEnd;
        courseDate.attributes.unitIds = []; // reset it
      } //----------------------
      // Overwrite old courseDate
      //----------------------


      modifiedDates.set(dateString, courseDate); //-------------------
      // Increment counters
      //-------------------

      datesLaidDown++;
      date = dateFns.addDays(date, 1);
    }

    return _tsBelt.A.map(courseDates, cd => (0, _course2.isCourseDateCustom)(cd) ? modifiedDates.get(cd.attributes.dateString) || cd : cd).concat(newCourseDates);
  }

  function moveEndOfUnit(amount, newEndDate, unitHash, courseDates, units, datesOff, schoolDays) {
    if (amount === 0) return courseDates; // -------------------------------------------------------
    // Take the unit id out of the unitEnd
    // -------------------------------------------------------

    let courseDatesWithoutUnitEnd = _tsBelt.A.map(_tsBelt.A.filter(courseDates, _course2.isCourseDateCustom), courseDate => {
      if (_tsBelt.A.includes(courseDate.attributes.unitEnd, unitHash.id)) {
        let newCourseDate = cloneDeep(courseDate);
        (0, _lodashEs.pull)(newCourseDate.attributes.unitEnd, unitHash.id);
        return newCourseDate;
      } else {
        return courseDate;
      }
    });

    let courseDateMap = new Map(Object.entries((0, _lodashEs.keyBy)(courseDatesWithoutUnitEnd, "attributes.dateString")));
    let newCourseDates = [];
    let modifiedDates = new Map();

    let defaultCourseDates = _tsBelt.A.filter(courseDates, _course2.isCourseDateDefault);

    let date = dateFns.parse(newEndDate);
    let iterations = 0;
    let isFinding = true;

    while (isFinding && iterations < 500) {
      iterations++;
      let dateString = formatDateAsISO(date); // -------------------------------------------------------
      // Move to the next date if the day isn't in session
      // -------------------------------------------------------

      if (!_tsBelt.A.includes(schoolDays, dateFns.getDay(date))) {
        date = amount > 0 ? dateFns.addDays(date, 1) : dateFns.subDays(date, 1);
        continue;
      }

      if (_tsBelt.A.includes(datesOff, dateString)) {
        date = amount > 0 ? dateFns.addDays(date, 1) : dateFns.subDays(date, 1);
        continue;
      } // -------------------------------------------------------
      // Find the course date (if it exists)
      // -------------------------------------------------------


      let courseDate = courseDateMap.get(dateString) ? courseDateMap.get(dateString) : undefined; // -------------------------------------------------------
      // CASE 2: We have a date bigger or less than the max or min
      // So we reset it
      // -------------------------------------------------------
      // Case 2a. See if it exists max date
      // -------------------------------------------------------

      let maxDate = (0, _tsBelt.pipe)(units, _tsBelt.A.filter(unit => (0, _lodashEs.includes)(unitHash.ancestorIds, unit.id)), _tsBelt.A.map(unit => unit.endDate), endDates => (0, _lodashEs.min)(endDates));

      if (unitHash.ancestorIds && maxDate && dateString > maxDate) {
        date = dateFns.parse(maxDate);
        continue;
      } // Case 2b. See if it exists min date
      // -------------------------------------------------------


      let minDate = unitHash.startDate;

      if (dateString < minDate) {
        date = dateFns.parse(minDate);
        continue;
      } // CASE 2c: We have a date with the same bounds as it's parent
      // This is a bit confusing -- we look to see if it has the same start/endDate
      // as it's nearest parent. We tax the highest startDate of it's ancestors
      // and then check if we're at the max date and, if so, decrement the enddate
      // so it doesn't have the same start/end as it's parent
      // -------------------------------------------------------


      let parentUnitStartDate = (0, _tsBelt.pipe)(units, _tsBelt.A.filter(unit => (0, _lodashEs.includes)(unitHash.ancestorIds, unit.id)), _tsBelt.A.map(unit => unit.startDate), dates => (0, _lodashEs.max)(dates));

      if (unitHash.startDate === parentUnitStartDate && maxDate === dateString) {
        date = dateFns.subDays(date, 1);
        continue;
      } // CASE 2d: We have a date with the same bounds as it's child
      // -------------------------------------------------------


      let childUnitsWithSameStart = (0, _lodashEs.filter)(units, unit => {
        return (0, _lodashEs.includes)(unitHash.descendantIds, unit.id) && unit.startDate === unitHash.startDate;
      });

      let childUnitsWithSameStartIds = _tsBelt.A.map(childUnitsWithSameStart, unit => unit.id);

      let childUnitEndDates = _tsBelt.A.map(childUnitsWithSameStart, unit => unit.endDate);

      if (_tsBelt.A.includes(childUnitEndDates, dateString)) {
        date = dateFns.subDays(date, 1); // This is hacky -- we actually need to iterate to make sure it's not a day off or not a school day

        continue;
      } // -------------------------------------------------------
      // CASE 1: We don't have a course date
      // So we just apply it.
      // -------------------------------------------------------


      if (courseDate === undefined) {
        newCourseDates.push({
          id: dateString,
          type: "course-date-custom",
          attributes: {
            dateString: dateString,
            unitEnd: [unitHash.id],
            unitStart: [],
            unitIds: [],
            isForcedOn: false,
            cardStackId: null,
            time: null
          }
        });
        isFinding = false;
        continue;
      } else {
        // -------------------------------------------------------
        // CASE 3: We have a unit there
        // -------------------------------------------------------
        let currentUnits = (0, _lodashEs.filter)(courseDate.attributes.unitIds, id => {
          return (0, _lodashEs.includes)(unitHash.ancestorIds, id) === false && (0, _lodashEs.includes)(courseDate.attributes.unitEnd, id) === false && unitHash.id !== id && (0, _lodashEs.includes)(childUnitsWithSameStartIds, id) !== true;
        }); // CASE 3A: we're moving forward
        // So, we look for the end of the unit we dragged it into
        // -------------------------------------------------------

        if (currentUnits.length > 0 && amount > 0) {
          date = (0, _tsBelt.pipe)(courseDate.attributes.unitIds, arr => without(arr, unitHash.id), _tsBelt.A.map(id => _tsBelt.A.find(units, unit => unit.id === id && unit.ancestorIds.length === unitHash.ancestorIds.length)), arr => (0, _lodashEs.compact)(arr), _tsBelt.A.map(unit => unit.endDate), arr => (0, _lodashEs.max)(arr), endDate => dateFns.parse(endDate));
          continue;
        } // CASE 3B: we're moving backwards
        // So, we look for the start of the unit we dragged it into
        // ----------------------------


        if (currentUnits.length > 0 && amount < 0) {
          date = (0, _tsBelt.pipe)(courseDate.attributes.unitIds, ids => without(ids, unitHash.id), _tsBelt.A.map(id => _tsBelt.A.find(units, unit => unit.id === id && unit.ancestorIds.length >= unitHash.ancestorIds.length)), arr => (0, _lodashEs.compact)(arr), _tsBelt.A.map(unit => unit.startDate), dates => (0, _lodashEs.min)(dates), date => dateFns.parse(date), date => dateFns.subDays(date, 1) // This is hacky -- we actually need to iterate to make sure it's not a day off or not a school day
          );
          continue;
        } // -------------------------------------------------------
        // CASE 4: We have a course date AND we're not in a unit
        // So we just apply it
        // -------------------------------------------------------


        let cloned = cloneDeep(courseDate);
        cloned.attributes.unitEnd.unshift(unitHash.id);
        modifiedDates.set(dateString, cloned);
        isFinding = false;
        continue;
      }
    }

    return _tsBelt.A.map(courseDatesWithoutUnitEnd, cd => modifiedDates.get(cd.attributes.dateString) || cd).concat(newCourseDates).concat(defaultCourseDates);
  }

  function moveStartOfUnit(amount, newStartDate, unitHash, courseDates, units, datesOff, schoolDays) {
    if (amount === 0) return courseDates;

    let defaultCourseDates = _tsBelt.A.filter(courseDates, _course2.isCourseDateDefault); // -------------------------------------------------------
    // Take the unit id out of the unitStart
    // -------------------------------------------------------


    let courseDatesWithoutUnitStart = _tsBelt.A.map(_tsBelt.A.filter(courseDates, _course2.isCourseDateCustom), courseDate => {
      if (_tsBelt.A.includes(courseDate.attributes.unitStart, unitHash.id)) {
        let newCourseDate = cloneDeep(courseDate);
        (0, _lodashEs.pull)(newCourseDate.attributes.unitStart, unitHash.id);
        return newCourseDate;
      } else {
        return courseDate;
      }
    });

    let courseDateMap = new Map(Object.entries((0, _lodashEs.keyBy)(courseDatesWithoutUnitStart, "attributes.dateString")));
    let newCourseDates = [];
    let modifiedDates = new Map();
    let date = dateFns.parse(newStartDate);
    let iterations = 0;
    let isFinding = true;

    while (isFinding && iterations < 500) {
      if (iterations === 499) {// console.log("TOO MANY ITERATIONS")
      }

      iterations++;
      let dateString = formatDateAsISO(date); // -------------------------------------------------------
      // Move to the next date if the day isn't in session
      // -------------------------------------------------------

      if (!_tsBelt.A.includes(schoolDays, dateFns.getDay(date))) {
        date = amount > 0 ? dateFns.addDays(date, 1) : dateFns.subDays(date, 1); // console.log("not a school day")

        continue;
      }

      if (_tsBelt.A.includes(datesOff, dateString)) {
        date = amount > 0 ? dateFns.addDays(date, 1) : dateFns.subDays(date, 1); // console.log("date off")

        continue;
      } // -------------------------------------------------------
      // Find the course date (if it exists)
      // -------------------------------------------------------


      let courseDate = courseDateMap.get(dateString) ? courseDateMap.get(dateString) : undefined; // -------------------------------------------------------
      // CASE 2: We have a date bigger or less than the max or min
      // of the parents So we reset it
      // -------------------------------------------------------
      // Case 2a. See if there's an ancestor with a shorter start date
      // -------------------------------------------------------

      let minDate = (0, _tsBelt.pipe)(units, _tsBelt.A.filter(unit => (0, _lodashEs.includes)(unitHash.ancestorIds, unit.id)), _tsBelt.A.map(unit => unit.startDate), dates => (0, _lodashEs.min)(dates));

      if (minDate && unitHash.ancestorIds && dateString < minDate) {
        date = dateFns.parse(minDate); // console.log("case 2a: see if there's an ancestor with a shorter start date")

        continue;
      } // Case 2b. See if we're dragging past the end of the unit
      // -------------------------------------------------------


      let maxDate = unitHash.endDate;

      if (dateString > maxDate) {
        date = dateFns.parse(maxDate); // console.log("Case 2b: We're dragging past the end of the unit")

        continue;
      } // CASE 2c: We have a date with the same bounds as it's parent
      // This is a bit confusing -- we look to see if it has the same start/endDate
      // as it's nearest parent. We tax the highest startDate of it's ancestors
      // and then check if we're at the max date and, if so, decrement the enddate
      // so it doesn't have the same start/end as it's parent
      // -------------------------------------------------------


      let parentUnitStartDate = (0, _tsBelt.pipe)(units, _tsBelt.A.filter(unit => (0, _lodashEs.includes)(unitHash.ancestorIds, unit.id)), _tsBelt.A.map(unit => unit.startDate), dates => (0, _lodashEs.max)(dates));

      if (unitHash.startDate === parentUnitStartDate && maxDate === dateString) {
        date = dateFns.addDays(date, 1); // console.log("Case 2c: We have a date with the same bounds as it's parent")

        continue;
      } // CASE 2d: We have a date with the same bounds as it's child
      // -------------------------------------------------------


      let childUnitsWithSameStart = (0, _lodashEs.filter)(units, unit => {
        return (0, _lodashEs.includes)(unitHash.descendantIds, unit.id) && unit.startDate === unitHash.startDate;
      });

      let childUnitsWithSameStartIds = _tsBelt.A.map(childUnitsWithSameStart, unit => unit.id);

      let childUnitStartDates = _tsBelt.A.map(childUnitsWithSameStart, unit => unit.startDate);

      if (_tsBelt.A.includes(childUnitStartDates, dateString)) {
        date = dateFns.addDays(date, 1); // This is hacky -- we actually need to iterate to make sure it's not a day off or not a school day
        // console.log("case 2d: We have a date with the same bounds as it's child")

        continue;
      } // -------------------------------------------------------
      // CASE 1: We don't have a course date
      // So we just apply it.
      // -------------------------------------------------------


      if (courseDate === undefined) {
        // console.log("CASE 1: We don't have a course date")
        newCourseDates.push({
          id: dateString,
          type: "course-date-custom",
          attributes: (0, _courseCalendarDateCustom.createCourseCalendarDateCustomAttributes)({
            dateString: dateString,
            unitStart: [unitHash.id]
          })
        });
        isFinding = false;
        continue;
      } // -------------------------------------------------------
      // CASE 3: We have a unit there
      // -------------------------------------------------------


      let currentUnits = (0, _lodashEs.filter)(courseDate.attributes.unitIds, id => {
        return (0, _lodashEs.includes)(unitHash.ancestorIds, id) === false && (0, _lodashEs.includes)(courseDate.attributes.unitStart, id) === false && unitHash.id !== id && (0, _lodashEs.includes)(childUnitsWithSameStartIds, id) !== true;
      }); // CASE 3A: we're moving backwards
      // So, we look for the start of the unit we dragged it into
      // -------------------------------------------------------

      if (currentUnits.length > 0 && amount < 0) {
        date = (0, _tsBelt.pipe)(courseDate.attributes.unitIds, arr => without(arr, unitHash.id), _tsBelt.A.map(id => _tsBelt.A.find(units, unit => unit.id === id && unit.ancestorIds.length === unitHash.ancestorIds.length)), arr => (0, _lodashEs.compact)(arr), _tsBelt.A.map(unit => unit.startDate), arr => (0, _lodashEs.min)(arr), startDate => dateFns.parse(startDate)); // console.log("case 3a: we're moving backwards")

        continue;
      } // CASE 3B: we're moving forwards
      // So, we look for the end of the unit we dragged it into
      // ----------------------------


      if (currentUnits.length > 0 && amount > 0) {
        // console.log("CASE 3b: we're moving forwards", currentUnits, date)
        date = (0, _tsBelt.pipe)(courseDate.attributes.unitIds, arr => without(arr, unitHash.id), _tsBelt.A.map(id => _tsBelt.A.find(units, unit => unit.id === id && unit.ancestorIds.length >= unitHash.ancestorIds.length)), arr => (0, _lodashEs.compact)(arr), _tsBelt.A.map(unit => unit.endDate), arr => (0, _lodashEs.min)(arr), date => dateFns.parse(date), date => dateFns.addDays(date, 1) // This is hacky -- we actually need to iterate to make sure it's not a day off or not a school day
        ); // console.log("advanced date to", date)
        // isFinding = false

        continue;
      } // -------------------------------------------------------
      // CASE 4: We have a course date AND we're not in a unit
      // So we just apply it
      // -------------------------------------------------------
      // console.log("CASE 4: We have a course date AND we're not in a unit")
      // console.log("dateString", dateString, courseDate)


      let cloned = cloneDeep(courseDate);
      cloned.attributes.unitStart.unshift(unitHash.id);
      modifiedDates.set(dateString, cloned);
      isFinding = false;
      continue;
    } // console.log("update modified dates", modifiedDates)
    // console.log(_.map(modifiedDates, (d) => d.attributes.dateString))


    return _tsBelt.A.map(courseDatesWithoutUnitStart, cd => modifiedDates.get(cd.attributes.dateString) || cd).concat(newCourseDates).concat(defaultCourseDates);
  }
  /**
   */


  function constructUnitsAndDates(allCourseDates, datesOff, schoolDays) {
    // loop through dates and find min/max
    // add through, jumping over dates that are off and don't have unitStart/unitEnd
    // reduce to create the unit map
    // annotate the units with the ancestor/descendant ids
    // annotate the dates with unit ids
    let defaultCourseDates = _tsBelt.A.filter(allCourseDates, _course2.isCourseDateDefault);

    let customCourseDates = _tsBelt.A.sortBy((0, _lodashEs.filter)(allCourseDates, _course2.isCourseDateCustom), date => date.attributes.dateString);

    let courseDateStrings = _tsBelt.A.map(customCourseDates, date => date.attributes.dateString);

    let range = createRange( // TS is not smart enough to realize undefined is only returned for empty arrays and this won't be empty
    (0, _lodashEs.min)(courseDateStrings), // TS is not smart enough to realize undefined is only returned for empty arrays and this won't be empty
    (0, _lodashEs.max)(courseDateStrings), customCourseDates, datesOff, schoolDays);
    let initialAccumulator = {
      newCourseDatesMap: new Map(),
      units: new Map(),
      currentUnitIds: new Array()
    };

    let {
      newCourseDatesMap,
      units
    } = _tsBelt.A.reduceWithIndex(range, initialAccumulator, (acc, courseDate, index) => {
      (0, _lodashEs.forEach)(courseDate.attributes.unitStart, id => {
        acc.units.set(id, (0, _course.createUnit)({
          id: id,
          startDate: courseDate.attributes.dateString,
          startIndex: index,
          endDate: "",
          unitLength: 0,
          ancestorIds: [],
          descendantIds: []
        }));
        acc.currentUnitIds.push(id);
      });
      courseDate.attributes.unitIds = (0, _lodashEs.clone)(acc.currentUnitIds);
      (0, _lodashEs.forEach)(courseDate.attributes.unitEnd, id => {
        let unit = acc.units.get(id);

        if (unit === undefined) {
          console.log("this unit doesnt exist", id);
        } else {
          unit.endDate = courseDate.attributes.dateString;
          unit.unitLength = index + 1 - unit.startIndex; // Add all the dates for a unit so we can easily find the index
          // of a particular day

          let dateStrings = (0, _tsBelt.pipe)(range, _tsBelt.A.reject(cd => cd === undefined), _tsBelt.A.filter(cd => // This is for TS. It is not needed.
          unit !== undefined && cd.attributes.dateString >= unit.startDate && cd.attributes.dateString <= unit.endDate), _tsBelt.A.map(cd => cd.attributes.dateString));
          unit.dateStrings = dateStrings;
          acc.units.set(id, unit);
        }

        (0, _lodashEs.pull)(acc.currentUnitIds, id);
      });
      acc.newCourseDatesMap.set(courseDate.attributes.dateString, courseDate);
      return acc;
    }); // let sortedUnits = sortBy(Array.from(units.values()), ["startDate", "unitLength"])


    let sortedUnits = _tsBelt.A.sortBy(Array.from(units.values()), unit => `${unit.startDate}:${unit.unitLength}`);

    _tsBelt.A.forEach(sortedUnits, unit => {
      _tsBelt.A.forEach(sortedUnits, unit2 => {
        // The happy path
        if (unit2.endDate < unit.startDate && unit2.startDate < unit.startDate) return; // if (unit2.startDate <=  unit.startDate && unit2.endDate >= unit.endDate && unit2.id !== unit.id && unit.startDate !== unit2.startDate && unit.endDate !== unit2.endDate){

        if (unit2.startDate <= unit.startDate && unit2.endDate >= unit.endDate && unit2.id !== unit.id && !(0, _lodashEs.includes)(unit2.ancestorIds, unit.id)) {
          unit.ancestorIds.push(unit2.id);
        } else if (unit2.startDate >= unit.startDate && unit2.endDate <= unit.endDate && unit2.id !== unit.id) {
          unit.descendantIds.push(unit2.id);
        } else if (unit2.id !== unit.id && unit2.startDate === unit.startDate && unit2.endDate === unit.endDate) {
          console.log("Units have the same dates");
        } else if (unit2.startDate <= unit.startDate && unit2.endDate >= unit.startDate && unit2.endDate < unit.endDate && unit2.id !== unit.id) {
          console.log(`unit1: ${unit.id}: ${unit.startDate} - ${unit.endDate}`);
          console.log(`unit2: ${unit2.id}: ${unit2.startDate} - ${unit2.endDate}`);
          throw Error("Units overlap: starts before and breaks in the middle");
        } else if (unit2.startDate >= unit.startDate && unit2.startDate <= unit.endDate && unit2.endDate > unit.endDate && unit2.id !== unit.id) {
          console.log(`unit1: ${unit.id}: ${unit.startDate} - ${unit.endDate}`);
          console.log(`unit2: ${unit2.id}: ${unit2.startDate} - ${unit2.endDate}`); // debugger

          throw Error("Units overlap: starts after and extends past");
        }
      });
    });

    let oldCourseDatesMap = _tsBelt.A.reduce(customCourseDates, new Map(), (acc, cd) => acc.set(cd.attributes.dateString, cd));

    let courseDatesUpdated = _tsBelt.A.map(customCourseDates, cd => newCourseDatesMap.get(cd.attributes.dateString) || cd);

    let addedCourseDates = _tsBelt.A.filter(Array.from(newCourseDatesMap.values()), courseDate => {
      return oldCourseDatesMap.get(courseDate.attributes.dateString) === undefined;
    });

    let finishedCourseDates = (0, _tsBelt.pipe)(courseDatesUpdated, _tsBelt.A.concat(addedCourseDates), _tsBelt.A.filter(date => isCourseDateWorthSaving(date)), _tsBelt.A.sortBy(cd => cd.attributes.dateString));
    verifyCourseDates(finishedCourseDates);
    let concatCourseDates = [];
    return {
      courseDates: (0, _lodashEs.concat)(concatCourseDates, finishedCourseDates, defaultCourseDates),
      units: Array.from(units.values())
    };
  }

  function createRange(startDate, endDate, courseDates, datesOff, schoolDays) {
    let courseDateMap = new Map(Object.entries((0, _lodashEs.keyBy)((0, _lodashEs.filter)(courseDates, _course2.isCourseDateCustom), "attributes.dateString")));
    let dateRange = dateFns.eachDay(dateFns.parse(startDate), dateFns.parse(endDate));
    let schoolDaysSet = new Set(schoolDays);
    let datesOffSet = new Set(datesOff);
    return (0, _tsBelt.pipe)(dateRange, _tsBelt.A.filter(date => schoolDaysSet.has(dateFns.getDay(date))), // filter for school days
    _tsBelt.A.map(date => formatDateAsISO(date)), _tsBelt.A.map(dateString => {
      return courseDateMap.get(dateString) || {
        id: dateString,
        type: "course-date-custom",
        attributes: (0, _courseCalendarDateCustom.createCourseCalendarDateCustomAttributes)({
          dateString: dateString
        })
      };
    }), _tsBelt.A.filter(_course2.isCourseDateCustom), _tsBelt.A.reject(courseDate => {
      // If there's a unit, we can't skip it -- otherwise, our array of dates will be missing a unit bookend
      return datesOffSet.has(courseDate.attributes.dateString) && (0, _lodashEs.size)(courseDate.attributes.unitStart) === 0 && (0, _lodashEs.size)(courseDate.attributes.unitEnd) === 0 && (0, _lodashEs.size)(courseDate.attributes.unitIds) === 0;
    }));
  } // the moving forward is more complex b/c we don't want to have a blank lesson
  // at the end of the unit. So, if it doesn't have a lesson, we don't bump it.
  // or, if we have a bunch more to bump, we want to push it out so we have space.
  // This last condition is definitely the most confusing and hard for me to think about


  function shouldPluckCourseDate(date, amount, bufferLength, isApplyingFirstLesson, dateToInsert) {
    if ((0, _lodashEs.isString)(date.attributes.cardStackId)) return true;
    let dateIsNotInAUnit = (0, _lodashEs.size)(date.attributes.unitIds) === 0;
    if (isApplyingFirstLesson && date.attributes.cardStackId === null && dateIsNotInAUnit) return false; // If we're moving the end of a unit, we need to bump the beginning of the next unit

    if ((0, _lodashEs.size)(dateToInsert.unitEnd) > 0 && (0, _lodashEs.size)(date.attributes.unitStart) > 0) return true; // If we only have one date to insert, we do it (given the conditions above haven't been met)

    if (bufferLength === 1) return false;

    if (amount < 0) {
      return (0, _lodashEs.size)(date.attributes.unitStart) > 0 || (0, _lodashEs.size)(date.attributes.unitEnd) > 0 || (0, _lodashEs.size)(date.attributes.unitIds) > 0;
    } else {
      return (0, _lodashEs.size)(date.attributes.unitIds) > 0 || // there's a unit
      (0, _lodashEs.size)(date.attributes.unitStart) > 0 || // it has a unit start
      (0, _lodashEs.size)(date.attributes.unitEnd) > 0 && date.attributes.cardStackId || // there's a unitEnd AND a lesson
      (0, _lodashEs.size)(date.attributes.unitEnd) > 0 && bufferLength > 1 // there's a unitEnd AND we still have more things to move
      ;
    }
  }

  function getCardStackIdForDate(dates, dateString) {
    return (0, _tsBelt.pipe)(dates, _tsBelt.A.filter(_course2.isCourseDateCustom), _tsBelt.A.find(date => date.attributes.dateString === dateString), date => date ? date.attributes.cardStackId : null);
  }

  function getCourseDateForCardStackId(dates, cardStackId) {
    return (0, _tsBelt.pipe)(dates, _tsBelt.A.filter(_course2.isCourseDateCustom), _tsBelt.A.find(date => date.attributes.cardStackId === cardStackId), date => date || null);
  }

  function isCourseDateWorthSaving(courseDate) {
    return courseDate.attributes.cardStackId !== null || courseDate.attributes.unitIds.length > 0 || courseDate.attributes.isForcedOn === true;
  }

  function verifyCourseDates(courseDates) {
    // make sure course starts have course ends
    // make sure all course ends are after or at course starts
    let unitMap = {};
    unitMap = (0, _tsBelt.pipe)(courseDates, _tsBelt.A.filter(_course2.isCourseDateCustom), _tsBelt.A.sortBy(date => date.attributes.dateString), _tsBelt.A.reduce(unitMap, (acc, date) => {
      _tsBelt.A.forEach(date.attributes.unitStart, id => {
        if (acc[id] !== undefined) {
          Sentry.configureScope(scope => {
            scope.setFingerprint(["unit-multiple-with-same-id"]);
          });
          throw Error(`Multiple units with the id: ${id}`);
        }

        acc[id] = {
          id: id,
          startDate: null,
          endDate: null
        };
        acc[id]["startDate"] = date.attributes.dateString;
      });

      _tsBelt.A.forEach(date.attributes.unitEnd, id => {
        if (acc[id] === undefined) {
          Sentry.configureScope(scope => {
            scope.setFingerprint(["unit-start-end-error"]);
          });
          throw Error(`Unit end comes before unit beginning, ${id}, ${date.attributes.dateString}`);
        }

        acc[id]["endDate"] = date.attributes.dateString;
      });

      return acc;
    }));

    _tsBelt.A.forEach(_tsBelt.D.values(unitMap), map => {
      if ((0, _lodashEs.isNil)(map.endDate) || (0, _lodashEs.isNil)(map.startDate) || map.endDate < map.startDate) {
        console.log(`Problematic unit: ${map.id}`);
        console.log(map);
        Sentry.configureScope(scope => {
          scope.setFingerprint(["unit-start-end-error"]);
        });
        throw Error("Unit starts don't match ends");
      }
    }); // let unitIdsFromCourseDates = _.chain(courseDates).filter(cd => cd.type === "course-date-custom").flatMap(cd => cd.attributes.unitIds).sortBy().uniq().value()
    // let unitIdsFromUnits = _.sortBy(_.map(units, "id"))
    // let unitIdsFromMap   = _.sortBy(_.map(unitMap, "id"))
    //
    // _.forEach(unitIdsFromUnits, (id, index) => {
    //   if (unitIdsFromMap[index] !== id) throw Error("Unit array doesn't match the units in the dates", unitIdsFromMap, unitIdsFromUnits)
    //   if (unitIdsFromCourseDates[index] !== id) throw Error("Unit array doesn't match the units in the dates", unitIdsFromMap, unitIdsFromCourseDates)
    // })

  }
});