define("cc-frontend/helpers/has-feature", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasFeature = hasFeature;
  _exports.default = void 0;
  const AVAILABLE_FEATURES = ["GOOGLE_CLASSROOM", "GC_TOPICS", "NON_MF_WEEKDAYS", "LESSON_REVIEW"];

  function hasFeature(args) {
    var _user$attributes;

    let [user, feature] = args;
    if ((0, _lodashEs.isNil)(user)) return false;

    if ((0, _lodashEs.includes)(AVAILABLE_FEATURES, feature) === false) {
      throw new Error(`Feature Flag Not Implemented: ${feature}. Add to has-feature.ts and cc.com/admin-backend/feature-flags.`);
    }

    return Ember.get(user === null || user === void 0 ? void 0 : (_user$attributes = user.attributes) === null || _user$attributes === void 0 ? void 0 : _user$attributes.featureFlags, feature);
  }

  var _default = Ember.Helper.helper(hasFeature);

  _exports.default = _default;
});