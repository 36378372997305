define("cc-frontend/services/session", ["exports", "@sentry/browser", "cc-frontend/helpers/find-document", "cc-frontend/lib/object-id-gen", "js-base64", "lodash-es", "remeda", "cc-frontend/app", "cc-frontend/config/environment"], function (_exports, Sentry, _findDocument, _objectIdGen, _jsBase, _lodashEs, R, _app, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(_jsBase.Base64.decode(base64));
  }

  let Session = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = (0, _findDocument.default)("id", "session"), _dec9 = (0, _findDocument.default)("userId", "user"), _dec10 = Ember.computed("token"), _dec11 = Ember.computed("user.isFulfilled", "user.attributes.isPro"), _dec12 = Ember.computed("user.isFulfilled", "user.attributes.fiscalGroupId"), (_class = class Session extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "socket", _descriptor2, this);

      _initializerDefineProperty(this, "rpc", _descriptor3, this);

      _initializerDefineProperty(this, "sessionStore", _descriptor4, this);

      _initializerDefineProperty(this, "fastboot", _descriptor5, this);

      _initializerDefineProperty(this, "dialog", _descriptor6, this);

      _initializerDefineProperty(this, "cookies", _descriptor7, this);

      _defineProperty(this, "id", void 0);

      _defineProperty(this, "userId", null);

      _defineProperty(this, "token", null);

      _defineProperty(this, "browserId", null);

      _defineProperty(this, "isImpersonating", false);

      _defineProperty(this, "isInStoryBook", false);

      _defineProperty(this, "isAuthenticating", null);

      _initializerDefineProperty(this, "session", _descriptor8, this);

      _initializerDefineProperty(this, "user", _descriptor9, this);

      try {
        this.isInStoryBook = typeof window !== "undefined" && window.parent && window.parent.location.href.search("http://localhost:9001") > -1;
      } catch {
        console.log("In iframe probably");
      }
    }

    /**
     * Sets:
     * - An ID for the session
     * - An ID for the browser if none exists
     *
     * Also:
     * - Creates a function on window for impersonating.
     */
    initiate() {
      let sessionId = _objectIdGen.default.create();

      Ember.set(this, "id", sessionId);

      if (window && window.sessionStorage) {
        let existingStartTime = sessionStorage.getItem("CC_SESSION_START_TIME");

        if ((0, _lodashEs.isNil)(existingStartTime)) {
          sessionStorage.setItem("CC_SESSION_START_TIME", Date.now().toString());
        }
      }

      if (typeof FastBoot === "undefined" && _environment.default.environment !== "test") {
        let browserData = Ember.get(this, "sessionStore").fetchObject("browserData");

        if (browserData === null || typeof browserData.id !== "string") {
          browserData = Ember.get(this, "sessionStore").persistObject("browserData", {
            id: _objectIdGen.default.create()
          });
        }

        Ember.set(this, "browserId", browserData.id);
        console.log(`session:${Ember.get(this, "id")}  browser:${Ember.get(this, "browserId")}`);
        Ember.get(this, "cookies").write("cc_session_id", Ember.get(this, "id"));
      }

      if (window) {
        // @ts-ignore
        window.CCSTOP_IMPERSONATING = () => {
          this.stopImpersonating();
          window.location.reload();
        };
      }

      if (_environment.default.environment !== "test") {
        this.restore();
      }
    }

    setTokenAndAuthenticate(payload) {
      this.setToken(payload.relationships.user.data.id, payload.attributes.token, false);
      this.socket.authenticateWithToken(payload.attributes.token);
    }

    setToken(userId, token, isImpersonating) {
      let sessionId = Ember.get(this, "id");
      Ember.get(this, "sessionStore").persistObject("sessionStore", {
        token: token,
        userId: userId,
        sessionId: sessionId,
        isImpersonating: isImpersonating
      });
      Ember.get(this, "cookies").write("cc_auth_token", token);
      Ember.set(this, "token", token);
      Ember.set(this, "userId", userId);
    }

    restore() {
      this.isAuthenticating = true;
      let token;
      let userId;
      let isImpersonating;
      if (_environment.default.environment === "test") return; // @ts-ignore

      if (Ember.get(this, "fastboot.isFastBoot")) {
        // @ts-ignore
        token = Ember.get(this, "fastboot.request.cookies.cc_auth_token") || null;

        if (token) {
          let claims = parseJwt(token);
          let userClaim = claims.sub;
          userId = userClaim.replace("user:", "");
        }
      } else {
        let sessionStore = Ember.get(this, "sessionStore").fetchObject("sessionStore") || {};
        token = sessionStore.token;
        userId = sessionStore.userId;
        isImpersonating = sessionStore.isImpersonating;
      }

      if (token) Ember.set(this, "token", token);
      if (userId) Ember.set(this, "userId", userId);
      if (isImpersonating) Ember.set(this, "isImpersonating", true);
      this.isAuthenticating = false;
    }

    signOut() {
      Ember.get(this, "sessionStore").clear("sessionStore");
      Ember.get(this, "cookies").clear("cc_auth_token");
      Ember.get(this, "store").clearMemory();
      Ember.set(this, "token", null);
      Ember.set(this, "userId", null);

      if (window && window.localStorage) {
        window.localStorage.setItem("signOutNow", "true");
        window.localStorage.removeItem("signOutNow");
      }
    }

    impersonate(userId, newToken) {
      if (Ember.get(this, "isImpersonating") === false) {
        Ember.get(this, "sessionStore").persistObject("originalSession", {
          token: Ember.get(this, "token"),
          userId: Ember.get(this, "userId")
        });
      }

      Ember.set(this, "isImpersonating", true);
      this.setToken(userId, newToken, true);
      return userId;
    }

    stopImpersonating() {
      let {
        userId,
        token
      } = Ember.get(this, "sessionStore").fetchObject("originalSession") || {};
      this.setToken(userId, token, false);
    }

    impersonateUserId(userId, newLocation) {
      // this.userId is the current user. Return if not existant
      if ((0, _lodashEs.isNil)(this.userId)) return;

      if (Ember.get(this, "isImpersonating")) {
        this.stopImpersonating();
        return;
      } else {
        this.store.find("user", this.userId).then(currentUser => {
          if (currentUser.attributes.isSupport === true) {
            Ember.get(this, "rpc").execute("IMPERSONATE_BY_USER_ID", {
              userId: userId
            }).then(data => {
              this.impersonate(data.userId, data.token); // window.location.assign(newLocation) // if we don't want to open a new window

              window.open(newLocation);
            });
          } else {
            console.log("Must be logged into an account with support privleges.");
          }
        });
      }
    }

    get isAuthenticated() {
      return Ember.get(this, "token") !== null;
    }

    mustSignInToProceed(location) {
      if (window && window.analytics) {
        window.analytics.track("Must Sign In Modal", {
          location: location
        });
      }

      Ember.get(this, "dialog").blank("dialogs/alert-must-sign-in-to-proceed", {
        className: "dialog--create-annotation"
      }).catch(error => {
        // @ts-ignore
        if (error instanceof Error) (0, _app.default)(error);
      });
    }

    get isPro() {
      // @ts-ignore
      return Ember.get(this, "user.attributes.isPro");
    }

    get fiscalGroupId() {
      console.log("fiscalgroupid"); // @ts-ignore

      return Ember.get(this, "user.attributes.fiscalGroupId") || null;
    }

    sendUserToAnalytics() {
      if (Ember.get(this, "isImpersonating") === true) return;
      if ((0, _lodashEs.isNil)(this.userId)) return;
      this.store.find("user", this.userId).then(user => {
        var _user$attributes$nps, _user$attributes$nps$, _user$attributes$nps2, _user$attributes$nps3, _user$attributes$nps4, _user$attributes$flag, _user$attributes$flag2, _user$attributes$flag3;

        if (user === null || user === undefined) return;
        let enabledFeatureFlags = R.pipe(user.attributes.featureFlags, R.toPairs, R.filter(([k, value]) => {
          return value === true;
        }), R.map(([k, _value]) => {
          return k;
        })).join(" ");
        let mostRecentSubscription = (0, _lodashEs.last)((0, _lodashEs.sortBy)(user.attributes.subscriptions || [], "startedAt"));
        let attrs = {
          id: user.id,
          userId: user.id,
          sessionId: this.id,
          createdAt: user.attributes.createdAt,
          firstName: user.attributes.firstName,
          lastName: user.attributes.lastName,
          signInCount: user.attributes.signInCount,
          email: user.attributes.email,
          isPro: user.attributes.isPro,
          cc_internal_user_id: user.id,
          lastSignInAt: user.attributes.lastSignInAt,
          lastSignInAtDate: dateFns.format(dateFns.parse(user.attributes.lastSignInAt), "YYYY-MM-DD"),
          // Otherwise, we can get subscriptions which are too long and customer.io rejects the reqeuest
          subscriptions: [(0, _lodashEs.last)(user.attributes.subscriptions)],
          // For facebook: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters#subscription-id
          subscription_id: mostRecentSubscription === null || mostRecentSubscription === void 0 ? void 0 : mostRecentSubscription.id,
          educationRole: user.attributes.educationRole,
          educationRoleSpecific: user.attributes.educationRoleSpecific,
          title: user.attributes.title,
          workplace: user.attributes.workplace,
          canonicalGroupId: user.attributes.canonicalGroupId,
          onboardingGoals: (user.attributes.onboardingGoals || []).join(" "),
          stripeId: user.attributes.stripeId,
          product: user.attributes.product,
          classifications: user.attributes.classifications,
          clientVersion: _environment.default.CLIENT_VERSION,
          "Last Fullstory Session": (0, _app.generateFullStorySessionUrl)(),
          "NPS: Last Score": (_user$attributes$nps = user.attributes.nps) === null || _user$attributes$nps === void 0 ? void 0 : (_user$attributes$nps$ = _user$attributes$nps.lastScore) === null || _user$attributes$nps$ === void 0 ? void 0 : _user$attributes$nps$.score,
          "NPS: Last Score Created": (_user$attributes$nps2 = user.attributes.nps) === null || _user$attributes$nps2 === void 0 ? void 0 : (_user$attributes$nps3 = _user$attributes$nps2.lastScore) === null || _user$attributes$nps3 === void 0 ? void 0 : _user$attributes$nps3.scoreCreatedAt,
          "NPS: Historical Scores": (_user$attributes$nps4 = user.attributes.nps) === null || _user$attributes$nps4 === void 0 ? void 0 : _user$attributes$nps4.historicalScores,
          "Admin Link": `https://www.commoncurriculum.com/admin/users/${user.id}`,
          enabledFeatureFlags: enabledFeatureFlags,
          acceptedFromGroupInvite: (_user$attributes$flag = user.attributes.flags) === null || _user$attributes$flag === void 0 ? void 0 : _user$attributes$flag.acceptedFromGroupInvite,
          acceptedFromPlanbookInvite: (_user$attributes$flag2 = user.attributes.flags) === null || _user$attributes$flag2 === void 0 ? void 0 : _user$attributes$flag2.acceptedFromPlanbookInvite,
          acceptedFromGenericInvite: (_user$attributes$flag3 = user.attributes.flags) === null || _user$attributes$flag3 === void 0 ? void 0 : _user$attributes$flag3.acceptedFromGenericInvite
        };
        if (window && window.analytics) window.analytics.identify(Ember.get(user, "id"), attrs);

        if (window && window.gtag) {
          window.gtag("config", "GA_MEASUREMENT_ID", {
            user_id: user.id
          });
          window.gtag("set", "user_properties", {
            isPro: user.attributes.isPro,
            product: user.attributes.product,
            createdAt: user.attributes.createdAt
          });
        }

        Sentry.configureScope(scope => {
          scope.setUser(attrs);
          scope.setExtra("adminPath", `https://www.commoncurriculum.com/admin/user-session/${Ember.get(user, "id")}?sessionId=${Ember.get(this, "id")}`);
          scope.setExtra("fullStorySessionUrl", (0, _app.generateFullStorySessionUrl)());
        }); // @ts-ignore

        if (window && typeof profitwell !== "undefined") {
          try {
            // @ts-ignore
            profitwell("user_email", Ember.get(user, "attributes.email"));
          } catch (e) {
            console.log("ERROR LOADING PROFITWELL", e);
          }
        } // @ts-ignore


        if (typeof ga !== "undefined") {
          // @ts-ignore
          ga("set", "userId", user.id);
        }

        tryToSendToSessionRewind(attrs);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "socket", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionStore", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cookies", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isAuthenticated", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isAuthenticated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPro", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "isPro"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fiscalGroupId", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "fiscalGroupId"), _class.prototype)), _class));
  _exports.default = Session;

  function tryToSendToSessionRewind(attrs) {
    if (typeof window.sessionRewind !== "undefined" && typeof window.sessionRewind.identifyUser !== "undefined") {
      let stringified = R.mapValues(attrs, el => (0, _lodashEs.toString)(el)); // @ts-ignore I can't figure out how to make sure the method above doesn't return keys that are numbers

      window.sessionRewind.identifyUser(stringified);
    } else {
      setTimeout(() => {
        tryToSendToSessionRewind(attrs);
      }, 1000);
    }
  }
});