define("cc-frontend/lib/actions/course/calendar/ADD_UNIT", ["exports", "lodash-es", "cc-frontend/lib/patch-updater", "cc-frontend/lib/unit-utils", "moment", "@mobily/ts-belt", "cc-frontend/models/course", "cc-frontend/utils/filter-dates/course", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _lodashEs, _patchUpdater, _unitUtils, _moment, _tsBelt, _course, _course2, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "ADD_UNIT",
    params: {
      newUnitId: "string",
      unitStart: "string",
      course: "object",
      planbook: "object",
      rotationCalendar: "object",
      unitCards: "object"
    },

    patches(payload) {
      let createUnitPatch = _patchUpdater.default.create("card-stack", payload.newUnitId).set("attributes.usedAs", "unit").set("attributes.cards", payload.unitCards).set("meta", {
        permissions: ["can-edit-plan", "can-view-plan"]
      }).set("relationships.course.data", {
        id: payload.course.id,
        type: "course"
      }).set("relationships.planbook.data", {
        id: payload.planbook.id,
        type: "planbook"
      }).patch;

      let consolidatedCourseDatesOff = (0, _unitUtils.consolidateCourseDatesOff)(payload.course, payload.planbook, payload.rotationCalendar);
      let courseDateExists = (0, _tsBelt.pipe)(payload.course.attributes.calendar.dates, _tsBelt.A.filter(_course2.isCourseDateCustom), _tsBelt.A.find(date => date.attributes.dateString === payload.unitStart));
      let courseDatesWithUnit;

      if (courseDateExists === undefined || courseDateExists === null) {
        courseDatesWithUnit = payload.course.attributes.calendar.dates.concat([{
          id: payload.unitStart,
          type: "course-date-custom",
          attributes: (0, _course.createCourseDateCustomAttributes)({
            dateString: payload.unitStart,
            unitStart: [payload.newUnitId],
            unitEnd: [payload.newUnitId],
            unitIds: [payload.newUnitId]
          })
        }]);
      } else {
        courseDatesWithUnit = (0, _lodashEs.map)(payload.course.attributes.calendar.dates, courseDate => {
          if ((0, _course2.isCourseDateCustom)(courseDate) && courseDate.attributes.dateString === payload.unitStart) {
            let cd = (0, _lodashEs.cloneDeep)(courseDate);
            cd.attributes.unitStart.push(payload.newUnitId);
            cd.attributes.unitEnd.push(payload.newUnitId);
            return cd;
          } else {
            return courseDate;
          }
        });
      }

      let {
        courseDates,
        units
      } = (0, _unitUtils.constructUnitsAndDates)(courseDatesWithUnit, consolidatedCourseDatesOff, payload.rotationCalendar.attributes.schoolDays);

      let addUnitToCalendarPatch = _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", courseDates).set("attributes.calendar.units", units).inc("attributes.calendar.version", 1).patch;

      return [createUnitPatch, addUnitToCalendarPatch];
    },

    undoPatches(payload) {
      return _patchUpdater.default.create("course", payload.course.id).set("attributes.calendar.dates", JSON.parse(JSON.stringify(payload.course.attributes.calendar.dates))).set("attributes.calendar.units", JSON.parse(JSON.stringify(payload.course.attributes.calendar.units))).inc("attributes.calendar.version", 1).wrapInArray;
    },

    narrative(payload, findFn, userId) {
      let course = payload.course;
      let models = {
        course: course,
        planbook: findFn("planbook", (0, _lodashEs.get)(course, "relationships.planbook.data.id")),
        unit: findFn("card-stack", payload.newUnitId) || findFn("card-stack-summary", payload.newUnitId)
      };
      let courseTitle = (0, _lodashEs.get)(models, "course.attributes.title");
      let newDateFormatted = (0, _moment.default)(payload.unitStart).format("ddd, MMM. D, YYYY");
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: payload.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: null,
        primary_verb: "create",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, payload.planbook.id),
        primary_object_type: "unit",
        primary_object_id: payload.newUnitId,
        primary_object_specifier: null,
        primary_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id],
        adverbial_verb: "adding",
        adverbial_object_type: "unit",
        adverbial_object_id: payload.newUnitId,
        adverbial_object_specifier: null,
        adverbial_object_id_and_ancestor_ids: [payload.planbook.id, payload.course.id]
      };
      return {
        title: "Added Unit",
        titleForAnalytics: "Unit - Added",
        description: `Added unit to your ${courseTitle} class on ${newDateFormatted}`,
        context: {
          courseTitle,
          planbookTitle: (0, _lodashEs.get)(models, "planbook.attributes.title"),
          unitId: payload.newUnitId,
          courseId: (0, _lodashEs.get)(models, "course.id"),
          planbookId: (0, _lodashEs.get)(models, "planbook.id")
        },
        activity: {
          actor: `user-${userId}`,
          verb: "edited",
          object: `course-${payload.course.id}`,
          to: [`course-edits:course-${payload.course.id}`, `planbook-edits:planbook-${models.planbook.id}`]
        },
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.default = _default;
});