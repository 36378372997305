define("cc-frontend/lib/actions/lesson/LESSON_EDIT_TITLE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook", "lodash-es"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _base, _planbook, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = editLessonTitleAction;
  _exports.patches = patches;
  _exports.undoPatches = undoPatches;
  _exports.narrative = narrative;
  _exports.name = void 0;
  const name = "LESSON_EDIT_TITLE";
  _exports.name = name;

  function editLessonTitleAction(payload) {
    return {
      name: name,
      payload: payload,
      patches: patches(payload),
      undoPatches: undoPatches(payload),
      narrative: (0, _lodashEs.partial)(narrative, payload)
    };
  }

  function patches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", payload.value).patch;

    let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.title", payload.value).patch;

    return [patchForCardStackSummary, patch];
  }

  function undoPatches(payload) {
    let patch = _patchUpdater.default.create("card-stack", payload.cardStackId).set("attributes.title", payload.originalValue).patch;

    let patchForCardStackSummary = _patchUpdater.default.create("card-stack-summary", payload.cardStackId).set("attributes.title", payload.originalValue).patch;

    return [patchForCardStackSummary, patch];
  }

  function narrative(payload, findFn, userId) {
    // debugger;
    let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
    let context = (0, _defaultLessonContext.default)(payload, models);
    let newContext = (0, _lodashEs.assign)({}, context, {
      oldTitle: payload.originalValue,
      newTitle: payload.value
    });
    let newEvent = { ...(0, _base.createBlankEvent)(),
      root_id: models.planbook.id,
      root_type: "planbook",
      date_plan_scheduled_for: models.dateStringForCardStack,
      primary_object_id: payload.cardStackId,
      primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
      primary_verb: "edit",
      primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
      primary_object_type: "lesson",
      primary_object_specifier: "title",
      adverbial_verb: null,
      adverbial_object_type: null,
      adverbial_object_specifier: null,
      adverbial_object_id: null,
      adverbial_object_possessive: null,
      adverbial_object_id_and_ancestor_ids: null
    };
    return {
      title: "Renamed Lesson",
      titleForAnalytics: "Lesson - Edit Title",
      description: `Changed the title from "${payload.originalValue}" to "${payload.value}"`,
      activity: (0, _defaultLessonActivity.default)(payload, models),
      context: newContext,
      newAnalyticsEvents: [newEvent]
    };
  }
});