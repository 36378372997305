define("cc-frontend/services/planbook-editor", ["exports", "cc-frontend/lib/actions/course/calendar/BUMP_RANGE_TO_DATE", "cc-frontend/lib/actions/course/calendar/COURSE_DATE_SAVE_AS_TEMPLATE", "cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT", "cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY", "cc-frontend/lib/actions/planbook/date/PLANBOOK_DATE_ANNOTATION_UPDATE", "cc-frontend/lib/actions/planbook/lessons/PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY", "cc-frontend/lib/actions/planbook/PLANBOOK_UPDATE_INTEGRATION", "cc-frontend/lib/assign-new-card-ids", "cc-frontend/lib/object-id-gen", "cc-frontend/models/date-annotation", "cc-frontend/utils/filter-dates/course", "lodash-es"], function (_exports, _BUMP_RANGE_TO_DATE, _COURSE_DATE_SAVE_AS_TEMPLATE, _PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT, _PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY, _PLANBOOK_DATE_ANNOTATION_UPDATE, _PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY, _PLANBOOK_UPDATE_INTEGRATION, _assignNewCardIds, _objectIdGen, _dateAnnotation, _course, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlanbookEditor = (_dec = Ember.inject.service, (_class = class PlanbookEditor extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);
    }

    /*-------------------------------------------------------------------------------------------------------
    Planbook Date
    --------------------------------------------------------------------------------------------------------*/
    addAnnotation({
      title,
      description,
      startDate,
      endDate,
      isOff,
      planbook
    }) {
      let dateAnnotation = this.checkAnnotationType(title, description, startDate, endDate, isOff);
      this.store.dispatchPreparedAction((0, _PLANBOOK_DATE_ANNOTATION_ADD_SPECIAL_EVENT.preparePlanbookAddSpecialEvent)({
        dateAnnotation: dateAnnotation,
        planbook: planbook
      }));
    }

    updateAnnotation({
      oldAnnotation,
      title,
      description,
      startDate,
      endDate,
      planbook
    }) {
      let newAnnotation = (0, _lodashEs.cloneDeep)(oldAnnotation);
      newAnnotation.attributes.title = title;
      newAnnotation.attributes.description = description;

      if (newAnnotation.type === "date-annotation-planbook-custom-range") {
        newAnnotation.attributes.startDate = startDate;
        newAnnotation.attributes.endDate = endDate;
      } else {
        newAnnotation.attributes.dateString = startDate;
      }

      this.store.dispatchAction(_PLANBOOK_DATE_ANNOTATION_UPDATE.default, {
        annotation: newAnnotation,
        planbook: planbook
      });
    }

    addNoSchoolDay({
      bumpDeleteOrNothing,
      title,
      description,
      startDate,
      endDate,
      planbook,
      rotationCalendar,
      courses,
      isOff,
      dateString
    }) {
      let newPatches;
      let newUndoPatches;
      let dateAnnotation = this.checkAnnotationType(title, description, startDate, endDate, isOff);

      if (bumpDeleteOrNothing === "BUMP") {
        let result = (0, _lodashEs.reduce)(courses, (acc, course) => {
          let courseDate = (0, _course.findCourseDateCustom)(course, startDate);
          if (courseDate === undefined) throw Error("There should be a course date if we're bumping a lesson");
          if (courseDate.attributes.cardStackId === null) throw Error("There should be a cardStackId if we're going to bump it");
          let endDate;

          if (dateAnnotation.type === "date-annotation-planbook-custom-range") {
            endDate = dateAnnotation.attributes.endDate;
          } else {
            endDate = dateAnnotation.attributes.dateString;
          }

          let {
            patches,
            undoPatches
          } = (0, _BUMP_RANGE_TO_DATE.prepareBumpRangeToDate)({
            startDate: startDate,
            endDate: endDate,
            course: course,
            planbook: planbook,
            rotationCalendar: rotationCalendar
          });
          return {
            newPatches: (0, _lodashEs.isNil)(patches) ? acc.newPatches : acc.newPatches.concat(patches),
            newUndoPatches: (0, _lodashEs.isNil)(undoPatches) ? acc.newUndoPatches : acc.newUndoPatches.concat(undoPatches)
          };
        }, {
          newPatches: [],
          newUndoPatches: []
        });
        newPatches = result.newPatches;
        newUndoPatches = result.newUndoPatches;
      } else if (bumpDeleteOrNothing === "DELETE") {
        let days = dateFns.eachDay(dateFns.parse(dateAnnotation.attributes.startDate), dateFns.parse(dateAnnotation.attributes.endDate));
        let result = (0, _lodashEs.reduce)(days, (acc, day) => {
          let {
            patches,
            undoPatches
          } = (0, _PLANBOOK_ERASE_ALL_LESSONS_FOR_DAY.prepareEraseAllLessonsForDay)({
            courses: courses,
            dateString: dateFns.format(startDate, "YYYY-MM_DD"),
            planbookId: planbook.id
          });
          return {
            newPatches: (0, _lodashEs.isNil)(patches) ? acc.newPatches : acc.newPatches.concat(patches),
            newUndoPatches: (0, _lodashEs.isNil)(undoPatches) ? acc.newUndoPatches : acc.newUndoPatches.concat(undoPatches)
          };
        }, {
          newPatches: [],
          newUndoPatches: []
        });
        newPatches = result.newPatches;
        newUndoPatches = result.newUndoPatches;
      }

      this.store.dispatchPreparedAction((0, _PLANBOOK_DATE_ANNOTATION_NO_SCHOOL_DAY.preparePlanbookAddNoSchoolDay)({
        dateAnnotation: dateAnnotation,
        planbook: planbook,
        bumpOrDeletePatches: newPatches,
        bumpOrDeleteUndoPatches: newUndoPatches,
        dateString: startDate
      }));
    }

    checkAnnotationType(title, description, startDate, endDate, isOff) {
      let dateAnnotation;
      let isRange = !(0, _lodashEs.isNil)(endDate) && startDate !== endDate;

      if (isRange) {
        dateAnnotation = new _dateAnnotation.default({
          id: _objectIdGen.default.create(),
          type: "date-annotation-planbook-custom-range",
          attributes: {
            startDate: dateFns.format(startDate, "YYYY-MM-DD"),
            endDate: dateFns.format(endDate, "YYYY-MM-DD"),
            title: title,
            description: description,
            showAnnotation: true,
            isOff: isOff
          }
        });
      } else {
        dateAnnotation = new _dateAnnotation.default({
          id: _objectIdGen.default.create(),
          type: "date-annotation-planbook-custom-single",
          attributes: {
            dateString: dateFns.format(startDate, "YYYY-MM-DD"),
            title: title,
            description: description,
            showAnnotation: true,
            isOff: isOff
          }
        });
      }

      return dateAnnotation;
    }
    /*-------------------------------------------------------------------------------------------------------
    Template Related
    --------------------------------------------------------------------------------------------------------*/


    saveAsLessonTemplate(course, planbookId, cardStackAttributes, templateTitle) {
      let id = _objectIdGen.default.create(); //creates a new copy of the array with new ids


      let lessonAttributes = (0, _assignNewCardIds.default)(cardStackAttributes);
      lessonAttributes.title = templateTitle;
      this.store.dispatchPreparedAction((0, _COURSE_DATE_SAVE_AS_TEMPLATE.prepareSaveAsLessonTemplate)({
        newId: id,
        planbookId: planbookId,
        cardStackAttributes: lessonAttributes
      }));
    }
    /*-------------------------------------------------------------------------------------------------------
    Planbook Integration Stuff
    --------------------------------------------------------------------------------------------------------*/


    updatePlanbookIntegration(planbookId, integrationName, prop, value) {
      this.store.dispatchPreparedAction((0, _PLANBOOK_UPDATE_INTEGRATION.default)({
        planbookId: planbookId,
        integrationName: integrationName,
        prop: prop,
        value: value
      }));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PlanbookEditor;
});