define("cc-frontend/models/analytics/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createBlankEvent = createBlankEvent;

  /**
   * The verb list should stay short so we don't have similiar verbs that are
   * essentially the same
   */

  /**
   * This should stay a smaller, more thoughtful list. The more objects, the more
   * things to think about. We want to keep these meaningful.
   */

  /**
   * We should expect this to get quite long as there are quite a few
   * dimensions to objects. It's not a problem if this grows, but we should make
   * sure there isn't duplication.
   */

  /**
   * The verb list should stay short so we don't have similiar verbs that are
   * essentially the same
   */

  /**
   * This will grow unboundedly.
   */
  // interface FullAnalytics {
  // Action Info -- I think I can fill these in on the backend
  // date_action_performed: Date
  // event_timestamp: Date
  // action_id: string
  // fiscal_group_id: string | null
  // group_id: string | null
  // original_action_name: string | null
  // original_analytics_name: string | null
  // original_action_description: string | null
  // url: string | null
  // user_agent: string | null
  // ip: string | null
  // user_created_at_date: Date | null
  // planbook_created_at_date: Date | null
  // planbook_school_year_id: string | null
  // }
  function createBlankEvent() {
    return {
      date_plan_scheduled_for: null,
      adverbial_verb: null,
      planbook_id: null,
      primary_possessive: null,
      primary_verb: null,
      primary_object_type: null,
      primary_object_id: null,
      primary_object_id_and_ancestor_ids: null,
      primary_object_specifier: null,
      adverbial_object_type: null,
      adverbial_possessive: null,
      adverbial_object_specifier: null,
      app_location: null,
      adverbial_object_id: null,
      adverbial_object_id_and_ancestor_ids: null
    };
  } // export function createBlankEvent<T>(
  //   findFn: FindFn,
  //   userId: string,
  //   primaryObject: primary_object,
  //   attrs
  // ): T {
  //   let user = findFn<User>("user", userId)
  //   let subjectRole: "teacher" | "admin" =
  //     user.attributes.educationRole === "teacher" ? "teacher" : "admin"
  //   let ret = {
  //     adverbial_verb: null,
  //     planbook_id: null,
  //     subject_id: userId,
  //     subject_role: subjectRole,
  //     subject_sub_role: null,
  //     primary_possessive: null,
  //     primary_verb: null,
  //     primary_object: primaryObject,
  //     primary_object_id: null,
  //     primary_object_id_and_ancestor_ids: null,
  //     primary_object_specifier: null,
  //     adverbial_object: null,
  //     adverbial_possessive: null,
  //     adverbial_object_specifier: null,
  //     app_location: null,
  //     adverbial_object_id: null,
  //     adverbial_object_id_and_ancestor_ids: null,
  //   }
  //   // mutate the object
  //   forEach(Object.keys(attrs), (key) => {
  //     ret[key] = attrs[key]
  //   })
  //   return ret
  // }

});