define("cc-frontend/lib/actions/lesson/LESSON_CARD_PASTE", ["exports", "cc-frontend/lib/patch-updater", "cc-frontend/lib/actions/lesson/default-lesson-activity", "cc-frontend/lib/actions/lesson/default-lesson-context", "cc-frontend/lib/actions/lesson/default-lesson-models", "lodash-es", "cc-frontend/models/analytics/base", "cc-frontend/models/analytics/planbook"], function (_exports, _patchUpdater, _defaultLessonActivity, _defaultLessonContext, _defaultLessonModels, _lodashEs, _base, _planbook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LESSON_CARD_PASTE = void 0;
  const LESSON_CARD_PASTE = {
    name: "LESSON_CARD_PASTE",
    params: {
      cardStackId: "string",
      cardToReplaceId: "string",
      cardToPaste: "object",
      cardToReplace: "object"
    },

    patches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardToReplaceId
      });

      if (payload.cardToReplace.type === "card-standards" && payload.cardToPaste.type === "card-standards") {
        patch.addToSet("embedded", "attributes.standards", ["$each", payload.cardToPaste.attributes.standards]);
      } else {
        patch.set("embedded", `type`, payload.cardToPaste.type);
        (0, _lodashEs.each)(payload.cardToPaste.attributes, (val, key) => {
          if (key === "position") return;
          patch.set("embedded", `attributes.${key}`, val);
        });
      }

      return [patch.patch];
    },

    undoPatches(payload) {
      let patch = _patchUpdater.default.create("card-stack", payload.cardStackId, "attributes.cards", {
        id: payload.cardToReplaceId
      }).set("embedded", `type`, payload.cardToReplace.type);

      (0, _lodashEs.each)(JSON.parse(JSON.stringify(payload.cardToReplace.attributes)), (val, key) => {
        patch.set("embedded", `attributes.${key}`, val);
      });
      return [patch.patch];
    },

    narrative(payload, findFn, userId) {
      let models = (0, _defaultLessonModels.default)(payload, findFn, userId);
      let cardToPasteTitle = (0, _lodashEs.get)(payload.cardToPaste, "attributes.title");
      let cardToReplaceTitle = (0, _lodashEs.get)(payload.cardToReplace, "attributes.title");
      let context = (0, _defaultLessonContext.default)(payload, models);
      let newContext = (0, _lodashEs.assign)({}, context, {
        cardToPasteTitle,
        cardToPasteId: payload.cardToPaste.id,
        cardToReplaceTitle,
        cardToReplaceId: payload.cardToReplaceId
      });
      let newEvent = { ...(0, _base.createBlankEvent)(),
        root_id: models.planbook.id,
        root_type: "planbook",
        date_plan_scheduled_for: models.dateStringForCardStack,
        primary_object_id: payload.cardStackId,
        primary_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds,
        primary_verb: "edit",
        primary_possessive: (0, _planbook.getPossessiveForPlanbook)(findFn, userId, models.planbook.id),
        primary_object_type: "lesson",
        primary_object_specifier: "cards",
        adverbial_verb: "pasting",
        adverbial_object_type: "card",
        adverbial_object_specifier: null,
        adverbial_object_id: payload.cardToPaste.id,
        adverbial_object_possessive: null,
        adverbial_object_id_and_ancestor_ids: models.cardStackIdAndAncestorIds
      };
      return {
        title: "Pasted a Lesson Card",
        titleForAnalytics: "Lesson Card - Paste",
        description: `Pasted "${cardToPasteTitle}" on top of "${cardToReplaceTitle}" in your ${context.courseTitle} lesson on ${context.date}`,
        context: newContext,
        activity: (0, _defaultLessonActivity.default)(payload, models),
        newAnalyticsEvents: [newEvent]
      };
    }

  };
  _exports.LESSON_CARD_PASTE = LESSON_CARD_PASTE;
  var _default = LESSON_CARD_PASTE;
  _exports.default = _default;
});